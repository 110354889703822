<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row class="ml-0">
          <h4>
            Abilities Templates List
          </h4>
        </b-row>
        <!-- Table Top -->
        <!-- <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.abilitiestemplates.fetchingAbilitiesTemplatesList"
              @click="refreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh List</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$can('create', 'abilitiestemplates')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                :to="{ name: 'apps-abilities-add', params: { templatename: '' } }"
              >
                <feather-icon icon="PlusSquareIcon" /><span class="text-nowrap ml-1">Add Template</span>
              </b-button>
            </div>
          </b-col>
        </b-row> -->

        <b-row class="mt-3">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="this.$store.state.abilitiestemplates.fetchingAbilitiesTemplatesList"
              @click="refreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              v-if="$can('create', 'abilitiestemplates')"
              class="ml-2"
              variant="primary"
              :to="{ name: 'apps-abilities-add', params: { templatename: '' } }"
            >
              <feather-icon icon="PlusSquareIcon" /><span class="text-nowrap ml-1">Add Template</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTemplateListTable"
        class="position-relative"
        :items="fetchTemplates"
        responsive
        :fields="tableColumns"
        primary-key="tempatename"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <!-- Column: Template Name -->
        <template #cell(templateName)="data">
          <div class="text-nowrap">
            <b-link
              v-if="$can('update', 'abilitiestemplates')"
              :to="{ name: 'apps-abilities-edit', params: { templatename: data.item.templatename } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.templatename }}
            </b-link>
            <div
              v-else-if="$can('read', 'abilitiestemplates')"
            >
              {{ data.item.templatename }}
            </div>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$can('update', 'abilitiestemplates')"
              :to="{ name: 'apps-abilities-edit', params: { templatename: data.item.templatename } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'abilitiestemplates')"
              @click="deleteListItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTemplates"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BLink, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import abilitiesTemplatesTemplatesListList from './abilitiesTemplatesTemplatesList'
import abilitiesTemplatesStoreModule from '../abilitiesTemplatesStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  setup() {
    const TEMPLATE_APP_STORE_MODULE_NAME = 'app-abilities-templates'

    // Register module
    if (!store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(TEMPLATE_APP_STORE_MODULE_NAME, abilitiesTemplatesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refTemplateListTable,
      refetchData,

    } = abilitiesTemplatesTemplatesListList()

    return {
      fetchTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refTemplateListTable,
      refetchData,
      // Filter
      avatarText,
    }
  },
  methods: {
    refreshList() {
      this.$store.state.abilitiestemplates.fetchingAbilitiesTemplatesList = true
      this.refetchData()
    },
    deleteListItem(item) {
      console.log(item)
      if (this.$ability.can('delete', 'abilitiestemplates')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete the ${item.templatename} Abilities Template.`, {
            title: 'Delete Abilities Template',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const qvars = {
                templatename: item.templatename,
              }
              store
                .dispatch('app-abilities-templates/deleteTemplate', qvars)
                .then(response => {
                  console.log(response)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Abilities Template deleted successfully.',
                      icon: 'CheckSquareIcon',
                      variant: 'success',
                    },
                  })
                  this.refreshList()
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error deleting Abilities Template',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
